import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { CtFileUploaderComponent, SnackbarService } from "@ctsolution/ct-framework";
import { CTManagerImportConfiguration } from "./ct-manager-import.configuration";
import { CtWebapiGenericResponse } from "@ctsolution/ct-webapi";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { ImportItemCheckDialogComponent } from "./import-item-check-dialog/import-item-check-dialog.component";

@Component({
  selector: "ct-manager-import",
  templateUrl: "./ct-manager-import.component.html",
  styleUrls: ["./ct-manager-import.component.scss"]
})
export class CTManagerImportComponent {
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  @Input() configuration: CTManagerImportConfiguration | null = null;
  @ViewChild("Uploader") fileUploader: CtFileUploaderComponent | null = null;

  viewModel = {
    loadingData: false
  };

  constructor(private _snackbar: SnackbarService, private dialog: MatLegacyDialog) {
  }

  import<T>() {

    this.configuration?.CTFileUploaderConfiguration?.control.markAsTouched();

    if (!this.configuration?.CTFileUploaderConfiguration?.control.value) return;

    const file: File | null = (this.configuration?.CTFileUploaderConfiguration?.control.value ?? [])[0];

    if (!file || !this.configuration?.importSubscription) return;

    this.viewModel.loadingData = true;

    this.configuration
      .importSubscription(file)
      .subscribe({
        next: (response: CtWebapiGenericResponse<any>) => {

          if (response.Result?.MissingItemCodes) {

            this.dialog.open(ImportItemCheckDialogComponent, {
              data: response.Result,
              maxWidth: '600px', minWidth: '400px', disableClose: true
            }).afterClosed().subscribe(result => {

              if (result == true) this.importSuccess(response.Result.ImportedItemHandlings);

              this.viewModel.loadingData = false;

            })


          } else {

            if(response.Result?.ImportedItemHandlings)
              this.importSuccess(response.Result.ImportedItemHandlings);
            else
              this.importSuccess(response);

              //Questo component viene chiamato in diversi punti con dto differenti, per l'anagrafica articoli serve solo response e basta perchè Result sarà null
          }

        },
        error: () => this.viewModel.loadingData = false
      });

  }

  private importSuccess(response : any){

    this.viewModel.loadingData = false;
    this._snackbar.onSuccess();

    this.reset();

    if (this.configuration?.importSubscriptionCallBack) {

      this.configuration.importSubscriptionCallBack(response);

    }

  }

  downloadTemplate() {

    if (!this.configuration?.template?.path) return;

    let link = document.createElement("a");
    link.setAttribute("type", "hidden");
    link.href = this.configuration.template.path;
    link.download = `${this.configuration.template.fileName ?? 'CTMTemplate'}.csv`;
    document.body.appendChild(link);
    link.click();
    link.remove();

  }

  reset() {

    this.fileUploader?.reset();
    this.fileUploader?.configuration.control.markAsUntouched();

  }

  ngOnDestroy() {

    this.reset();

  }

}

export interface IItemHandlingsImportResult {

  MissingItemCodes: string[] | null
  ImportedItemHandlings: Array<any> | null
  TotalItems: number

}
