
import { Injectable } from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import { CtBinaryOperator, CtCardConfiguration, CtCardTemplate, CtModelConfiguration, CTModelDatatableFilter, CtModelDatatableOperators, CtModelService } from "@ctsolution/ct-framework";
import { CtManagerService } from "../../../_core/lib/ct-manager.service";
import { ItemHandlingRouteData } from "../../../_core/route-data/item-handling.route-data";
import { HandlingOriginEnum } from "../../../_core/enum/handling-origin.enum";

@Injectable()
export class DashboardOrderHelper {

  constructor(private router: Router, private managerService : CtManagerService,private modelService : CtModelService<any>) { }

  getOrderCounter(counterList: CtCardConfiguration[]) {

    this.getNotPricedOrderCounter(counterList);

  }

  private async getNotPricedOrderCounter(counterList: CtCardConfiguration[]) {

      this.managerService.getComponentOverride("dashboard", "order_counter_notPriced", ORDER_NOTPRICED_COUNTER).then((component) => {
        return;
      });

      const filters = [CTModelDatatableFilter
        .create()
        .setField("[Price] is null OR [Price] = ?")
        .setValue([0])
        .setOperatorType(CtBinaryOperator.OrOperator),

        CTModelDatatableFilter
        .create()
        .setField("Handling.HandlingOrigin")
        .setValue(HandlingOriginEnum.Supplier)
        .setOperatorType(CtBinaryOperator.Equal),
      ]

      const resultProspect = await this.getItemHandlingsByFilter(filters);

      ORDER_NOTPRICED_COUNTER
        .setDescription((resultProspect?.TotalElement ?? 0) > 0 ? resultProspect.TotalElement : "0");


      const queryObject = {
        constants: [
          {
            Field: "[Price] is null OR [Price] = ?",
            OperatorType: CtBinaryOperator.OrOperator,
            Value: [0]
          },
          {
            Field: "Handling.HandlingOrigin",
            OperatorType: CtBinaryOperator.Equal,
            Value: HandlingOriginEnum.Supplier
          },
        ]
      };

      const queryString = JSON.stringify(queryObject);
      const urlTree: UrlTree = this.router.createUrlTree(['/item-handling/list'], { queryParams: { q: queryString } });

      // Convertiamo l'UrlTree in una stringa e apriamo una nuova scheda
      const fullUrl = this.router.serializeUrl(urlTree);

      counterList.push(ORDER_NOTPRICED_COUNTER.setAction(() => window.open(fullUrl, '_blank')));

    }

      private getItemHandlingsByFilter(filters: Array<CTModelDatatableFilter>) {

        const configuration: CtModelConfiguration<any> = CtModelConfiguration
          .create()
          .setRouteData(ItemHandlingRouteData());

        const operators: CtModelDatatableOperators = CtModelDatatableOperators
          .create()
          .setFilters(filters);

        return new Promise<CtModelConfiguration<Array<any>>>(resolve => {

          this.modelService
            .getList(configuration, operators)
            .subscribe(response => resolve(response.Result))

        })

      }
}

export const ORDER_NOTPRICED_COUNTER: CtCardConfiguration = CtCardConfiguration.create()
  .setTitle("dashboard.orderNotPriced")
  .setVisible(true)
  .setTemplate(CtCardTemplate.COUNTER)
  .setClass("bg-warning text-white");
