import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemHandlingEditComponent } from './item-handling-edit/item-handling-edit.component';
import { ItemHandlingListComponent } from './item-handling-list/item-handling-list.component';
import { RouterModule } from '@angular/router';
import { ItemHandlingListModule } from './item-handling-list/item-handling-list.module';
import { ItemHandlingEditModule } from './item-handling-edit/item-handling-edit.module';

@NgModule({
  imports: [
    CommonModule,
    ItemHandlingListModule,
    ItemHandlingEditModule,
    RouterModule.forChild([
      {
        path: "",
        children: [
          {
            path: "",
            redirectTo: "list",
            pathMatch: "full"
          },
          {
            path: "create",
            component: ItemHandlingEditComponent
          },
          {
            path: "edit/:id",
            component: ItemHandlingEditComponent
          },
          {
            path: "list",
            component: ItemHandlingListComponent
          }
        ]
      }
    ])
  ]
})
export class ItemHandlingModule { }
