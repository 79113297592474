import { Injectable } from "@angular/core";
import { CtWebapiService, DataRequest, MethodEnum } from "@ctsolution/ct-webapi";
import { lastValueFrom } from "rxjs";

@Injectable()

export class ItemHandlingService {

  constructor(private _webapi : CtWebapiService){}

  async getItemSuggestedPrices(oid: number | null): Promise<any> {

    if(oid == null) return;

    const request: DataRequest = new DataRequest()
      .setUrl(`/itemHandling/GetItemSuggestedPrices/${oid}`)
      .setMethod(MethodEnum.GET);

    const response = await lastValueFrom(this._webapi.request(request));
    if (response.Result)
      return response.Result;
    else
      return null;

  }

}
