import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemHandlingEditComponent } from './item-handling-edit.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ItemHandlingEditComponent],
  exports : [ItemHandlingEditComponent]
})
export class ItemHandlingEditModule { }
