import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContactEditComponent} from './contact-edit.component';
import {CtModelModule} from "@ctsolution/ct-framework";
import {CompanyContactModule} from "../../company-contact/company-contact.module";
import { ContactFeeModule } from './contact-fee/contact-fee.module';

@NgModule({
  declarations: [
    ContactEditComponent
  ],
  imports: [
    CommonModule,
    CtModelModule,
    CompanyContactModule,
    ContactFeeModule
  ],
  exports: [
    ContactEditComponent
  ]
})
export class ContactEditModule {
}
