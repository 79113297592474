<div mat-dialog-content>

  <p> <strong>Verranno caricati {{data.ImportedItemHandlings?.length ?? 0}} elementi su {{data.TotalItems}} presenti nel file di import.</strong></p>

  <ng-container *ngIf="data.MissingItemCodes?.length > 0">

    <p>Non saranno caricati i seguenti elementi con codice:</p>

    <div class="items-container">
      <ul>
        <li *ngFor="let item of data.MissingItemCodes; trackBy: trackByIndex">{{ item }}</li>
      </ul>
    </div>

  </ng-container>

</div>

<p>Clicca su Conferma per caricare gli elementi, altrimenti Annulla.</p>

<div mat-dialog-actions style="justify-content: space-between;">

  <ct-button [configuration]="cancelButton"></ct-button>

  <ct-button *ngIf="data.MissingItemCodes?.length > 0" [configuration]="copyButton"></ct-button>

  <ct-button [configuration]="submitButton"></ct-button>

</div>

