import {Component, Inject, OnInit} from '@angular/core';
import {CtButtonConfiguration, MAT_RAISED_PRIMARY, MAT_RAISED_WARN} from "@ctsolution/ct-framework";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import {FormControl, Validators} from "@angular/forms";
import { ItemHandlingService } from 'projects/ct-manager/src/app/components/item-handling/item-handling.service';

@Component({
  selector: 'app-update-item-handling-price',
  templateUrl: './update-item-handling-price.component.html',
  styleUrls: ['./update-item-handling-price.component.scss']
})
export class UpdateItemHandlingPriceComponent implements OnInit {

  control: FormControl = new FormControl(null,[Validators.required,Validators.min(0)]);

  recommendedPrice : number | null = null;

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
  .create()
  .setLabel("close")
  .setAction(() => this.dialogRef.close(false))
  .setMatherialOptions(MAT_RAISED_WARN);

  submitButton: CtButtonConfiguration = CtButtonConfiguration
  .create()
  .setLabel("confirm")
  .setAction(() => this.submit())
  .setMatherialOptions(MAT_RAISED_PRIMARY);


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      itemOid: number,
    },
    private dialogRef: MatDialogRef<UpdateItemHandlingPriceComponent>,
    private itemHandlingService : ItemHandlingService
  ) {
  }

  async ngOnInit() {

    this.recommendedPrice = await this.itemHandlingService.getItemSuggestedPrices(this.data.itemOid);

  }

  submit(){


    if(this.control.valid == false) return;

    this.dialogRef.close(this.control.value);

  }

}
