import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkOrderDynamicFormComponent } from './work-order-dynamic-form.component';
import { CtExpansionPanelModule, CtFormGeneratorModule, CtModelModule } from '@ctsolution/ct-framework';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    CtFormGeneratorModule,
    CtModelModule,
    TranslateModule,
    CtExpansionPanelModule
  ],
  declarations: [WorkOrderDynamicFormComponent],
  exports: [WorkOrderDynamicFormComponent]
})
export class WorkOrderDynamicFormModule { }
