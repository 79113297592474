import {ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {
  UserWorkingHoursWorkOrderControlComponent
} from "./user-working-hours-work-order-control/user-working-hours-work-order-control.component";
import {
  UserWorkingHoursActivityTypeControlComponent
} from "./user-working-hours-activity-type-control/user-working-hours-activity-type-control.component";
import {
  UserWorkingHoursHoursControlComponent
} from "./user-working-hours-hours-control/user-working-hours-hours-control.component";
import {
  CtButtonConfiguration, CtControlValidator, CTDateAdapter,
  CtModelConfiguration,
  CtModelDatatableOperators,
  CtModelService,
  MAT_RAISED_ACCENT
} from "@ctsolution/ct-framework";
import {ActivityTypeEnum, UserWorkingHours} from "../../../../_core/classes/user-working-hours";
import {RoleService} from "../../../../_core/lib/role.service";
import {
  UserWorkingHoursUserInfoControlComponent
} from "./user-working-hours-user-info-control/user-working-hours-user-info-control.component";
import {UserWorkingHoursConfiguration} from "../../../../_core/route-data/user-working-hours.route-data";
import {
  UserWorkingHoursDictionaryInfoControlComponent
} from "./user-working-hours-dictionary-info-control/user-working-hours-dictionary-info-control.component";
import { ActivityTypeRouteData } from 'projects/ct-manager/src/app/_core/route-data/activity-type.route-data';
import { UserWorkingHoursMinuteControlComponent } from './user-working-hours-minute-wizard-form/user-working-hours-minute-wizard-form.component';
import { distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-user-working-hours-wizard-form',
  templateUrl: './user-working-hours-wizard-form.component.html',
  styleUrls: ['./user-working-hours-wizard-form.component.scss']
})
export class UserWorkingHoursWizardFormComponent {

  @Output() refresh: EventEmitter<UserWorkingHours> = new EventEmitter<UserWorkingHours>();
  @Input() currentDate: Date | null = null;

  @ViewChild(UserWorkingHoursWorkOrderControlComponent) userworkinghoursWorkorderControlComponent: UserWorkingHoursWorkOrderControlComponent | null = null;
  @ViewChild(UserWorkingHoursActivityTypeControlComponent) userworkinghoursActivitytypeControlComponent: UserWorkingHoursActivityTypeControlComponent | null = null;
  @ViewChild(UserWorkingHoursHoursControlComponent) userworkinghoursHoursControlComponent: UserWorkingHoursHoursControlComponent | null = null;
  @ViewChild(UserWorkingHoursMinuteControlComponent) userworkinghoursMinuteControlComponent: UserWorkingHoursMinuteControlComponent | null = null;
  @ViewChild(UserWorkingHoursUserInfoControlComponent) userworkinghoursUserinfoControlComponent: UserWorkingHoursUserInfoControlComponent | null = null;
  @ViewChild(UserWorkingHoursDictionaryInfoControlComponent) userWorkingHoursDictionaryInfoControlComponent: UserWorkingHoursDictionaryInfoControlComponent | null = null;

  driverControl: FormControl = new FormControl();
  bankHourControl: FormControl = new FormControl();
  visibleBankHourFlag : boolean = false;

  viewModel = {

    isAdministrator: false

  }

  form: FormGroup;

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.save")
    .setClass('ct-model-submit-button w-100')
    .setAction(() => this.send())
    .setMatherialOptions(MAT_RAISED_ACCENT);

  constructor(
    private fb: FormBuilder,
    private roleService: RoleService,
    private _model: CtModelService<any>,
    private cdr: ChangeDetectorRef) {

    this.form = this.fb.group({Oid: new FormControl(null)}, { validators: this.hoursAndMinutesValidator });

  }


  ngAfterViewInit() {

    this.setup();

    this.cdr.detectChanges();

  }

  hoursAndMinutesValidator(group: FormGroup) {
    const hours = group.get('Hours')?.value || 0;
    const minutes = group.get('Minute')?.value || 0;

    // Aggiunge errore se entrambi sono 0
    return hours === 0 && minutes === 0 ? { hoursAndMinutesZero: true } : null;
  }



  private async setup() {

    this.userworkinghoursWorkorderControlComponent
      ?.configuration
      ?.setFormParent(this.form);

    this.userworkinghoursUserinfoControlComponent
      ?.control
      .setFormParent(this.form);

    this.userworkinghoursActivitytypeControlComponent
      ?.control
      .setFormParent(this.form)

    this.userworkinghoursHoursControlComponent
      ?.control
      .setFormParent(this.form);

    this.userWorkingHoursDictionaryInfoControlComponent
      ?.control
      .setFormParent(this.form);

    this.userworkinghoursMinuteControlComponent
      ?.control
      .setFormParent(this.form);

    this.userworkinghoursUserinfoControlComponent
      ?.control
      .control
      ?.valueChanges
      .subscribe((userOid: number | null) => {

        if (userOid) {

          this.userworkinghoursHoursControlComponent
            ?.setupByUserInfo(userOid)

            this.userworkinghoursMinuteControlComponent
            ?.setupByUserInfo(userOid)

        }

      })

        this.userworkinghoursActivitytypeControlComponent
        ?.control
        .control
        ?.valueChanges
        .subscribe((value) => {

          const param = activityType.find(activity => activity.Oid == value)

          this.visibleBankHourFlag = param.Type === ActivityTypeEnum.HourBank

      })

    this.driverControl
      .valueChanges
      .subscribe((value) => {

        this.userWorkingHoursDictionaryInfoControlComponent
          ?.control
          .setValidators(value ? [CtControlValidator.create({name: "required"} as CtControlValidator)] : null)

        if (!value) {

          this.userWorkingHoursDictionaryInfoControlComponent
            ?.control
            .setValue(null);

        }

      })

      let activityType : Array<any> = [];

      const activityTypeConfiguration = CtModelConfiguration
        .create()
        .setRouteData(ActivityTypeRouteData())

      const operators: CtModelDatatableOperators = CtModelDatatableOperators
        .create();

      await this._model
        .getList(activityTypeConfiguration, operators)
        .subscribe(response => activityType = (response?.Result?.DataSource ?? []) as Array<any>);

    this.viewModel.isAdministrator = await this.roleService.is.administrator();

  }

  send() {

    this.form.markAllAsTouched();

    if (this.form.valid) {

      const value = this.form.value;

      const parameter: UserWorkingHours = UserWorkingHours
        .create(value)
        .setActivityType(value.ActivityType)
        .setDate(CTDateAdapter.create().transformDateToCurrentTimezone(this.currentDate ?? new Date()));

      if(value.Hours == 0 && value.Minute == 0)
      {

        return

      }

      if(value.Minute > 0)
      {

        const hours = this.FormattedDecimalHours(value.Hours, value.Minute);
        parameter.setHours(hours);

      }

      if(this.bankHourControl.value == true && parameter.Hours)
      {
        parameter.setHours(-Math.abs(parameter.Hours))
      }


      if (value.UserInfo) {

        parameter
          .setUserInfo(value.UserInfo)

      }

      if (value.WorkOrder) {


        parameter
          .setWorkOrder(value.WorkOrder.value ?? value.WorkOrder)

      }

      if (value.DictionaryInfo) {

        parameter
          .setDictionaryInfo(value.DictionaryInfo)

      }

      this._model
        .putInfos(UserWorkingHoursConfiguration(), parameter)
        ?.subscribe(
          () => this.refresh.emit(),
          (error) => console.error('An error occurred during execution:', error)
        );

    }

  }

   FormattedDecimalHours(hours: number, minute: number): number {

    // Calcoliamo la parte decimale dei minuti dividendoli per 60
    const decimal = minute / 60;

    // Sommiamo le ore alla parte decimale
    const result = hours >= 0 ? hours + decimal : hours - decimal;

    return result;

  }

}
