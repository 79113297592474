
<div mat-dialog-content>

  <mat-form-field>
    <mat-label>{{ "updateItemPrice.insertPriceLabel" | translate }}:</mat-label>

    <input
      matInput
      id="number"
      type="number"
      [formControl]="control">

    <mat-hint *ngIf="recommendedPrice" (click)="control.setValue(recommendedPrice)">Clicca qui per applicare prezzo consigliato: {{ recommendedPrice | currency: 'EUR' }}</mat-hint>

  </mat-form-field>

</div>

<div mat-dialog-actions style="justify-content: space-between;">

  <ct-button [configuration]="cancelButton"></ct-button>

  <ct-button [configuration]="submitButton"></ct-button>

</div>
