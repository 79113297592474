import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {DashboardComponent} from "./dashboard.component";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {CtCardModule, CtNoteWidgetModule} from "@ctsolution/ct-framework";
import {FlexLayoutModule} from "@angular/flex-layout";
import {
  UserWorkingHoursWizardModule
} from "../../components/user-working-hours/user-working-hours-wizard/user-working-hours-wizard.module";
import { DashboardLeadHelper } from "./helper/dashboard-lead.helper";
import { DashboardCustomerHelper } from "./helper/dashboard-customer.helper";
import { DashboardOrderHelper } from "./helper/dashboard-order.helper";

@NgModule({
  declarations: [
    DashboardComponent
  ],
    imports: [
        CommonModule,
        MatCardModule,
        CtCardModule,
        FlexLayoutModule,
        UserWorkingHoursWizardModule,
        CtNoteWidgetModule
    ],
    providers: [DashboardLeadHelper, DashboardCustomerHelper, DashboardOrderHelper]
})
export class DashboardModule {
}
