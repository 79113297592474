import {AfterViewInit, Component, Input, ViewChild} from "@angular/core";
import {SelectedItemsConfiguration} from "./selected-items.configuration";
import {HandlingStateEnum} from "../../../../../_core/enum/handling-state.enum";
import {ItemHandling} from "../../../../../_core/classes/item-handling";
import {OrderHandlingWizardStepViewModel} from "../order-handling-wizard-step2.component";
import {Table} from "primeng/table";
import {UnitsOfMeasurements} from "../../../../../_core/classes/units-of-measurements";
import {CtWebapiGenericResponse, CtWebapiService, DataRequest} from "@ctsolution/ct-webapi";
import {FileGet} from "../../../../../_core/classes/file-get";
import {FileService} from "../../../../../_core/lib/file.service";
import {CtModelConfiguration, CtModelDatatableOperators, CtModelService, SnackbarService} from "@ctsolution/ct-framework";
import {UnitsOfMeasurementsRouteData} from "../../../../../_core/route-data/units-of-measurements.route-data";
import { HandlingOriginEnum } from "projects/ct-manager/src/app/_core/enum/handling-origin.enum";
import { ActivatedRoute } from "@angular/router";
import { HandlingService } from "../../../handling.service";
import { UpdateItemHandlingPriceComponent } from "./update-item-handling-price/update-item-handling-price.component";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { ItemHandlingRouteData } from "projects/ct-manager/src/app/_core/route-data/item-handling.route-data";

@Component({
  selector: "app-selected-items",
  templateUrl: "./selected-items.component.html",
  styles: [
    `::ng-deep .selected-items-table {

      td {
        place-content:center;
      }

      .p-datatable-tbody {

        .status-toggle-component {

          button {

            height: 35px !important;

          }
        }

      }

      .p-inputtext {
        width: 100%
      }

      .status-toggle-component {

        button {

          mat-icon {

            margin: 5px;

          }
        }
      }

    }`]
})
export class SelectedItemsComponent implements AfterViewInit {

  @Input() configuration: SelectedItemsConfiguration | null = null;
  @Input() viewModel: OrderHandlingWizardStepViewModel = OrderHandlingWizardStepViewModel.create();
  @ViewChild('table', {static: false}) private table!: Table;

  availableUMs: UnitsOfMeasurements[] = [];
  currentDate: Date = new Date();
  isOrder : boolean = false;
  enableUpdatePrice : boolean = false;

  constructor(private snackbar: SnackbarService,private dialog: MatLegacyDialog,private modelService: CtModelService<any>, private webapi: CtWebapiService, private fileService: FileService,private route : ActivatedRoute,private handlingService : HandlingService) {

    const originPathValue: string | null = this.route.snapshot.paramMap.get("origin");
    this.isOrder = this.handlingService.getHandlingTypeByRouteValue(originPathValue) === HandlingOriginEnum.Supplier ? true : false;

  }


  ngAfterViewInit() {

    this.setupUM();

    if (this.viewModel.handlingIsDraftState || this.viewModel.handlingIsCheckInState || this.configuration?.editModeEnabled) {

      this.table.initRowEdit({});

    }
    else{

      this.enableUpdatePrice = true;

    }

  }

  // barcodeTrackingButton: CtButtonConfiguration = CtButtonConfiguration
  //   .create()
  //   .setLabel("barcodeTracking.barcodeTrackingLabel")
  //   .setAction(() => this.openBarcodeTracking())
  //   .setMatherialOptions(MAT_RAISED_ACCENT);

  // delete(row: SelectedItemConfiguration) {
  //
  //   const idx: number = (this.configuration?.itemHandlings ?? []).indexOf(row);
  //
  //   if (idx > -1) {
  //
  //     (this.configuration?.itemHandlings ?? []).splice(idx, 1);
  //
  //   }
  //
  // }

  getRemainingQuantity(itemHandling: ItemHandling) {

    if (!itemHandling.StartQty) return 0;

    return itemHandling.StartQty - (itemHandling.Qty ?? 0) - (itemHandling.PartialQty ?? 0);

  }

  setupUM() {

    const configuration: CtModelConfiguration<any> = CtModelConfiguration
      .create()
      .setRouteData(UnitsOfMeasurementsRouteData());

    const operators: CtModelDatatableOperators = CtModelDatatableOperators
      .create();

    this.modelService
      .getList(configuration, operators)
      .subscribe(response => this.availableUMs = (response?.Result?.DataSource ?? []) as Array<UnitsOfMeasurements>);

  }

  deleteRow(index: number) {

    (this.configuration?.itemHandlings ?? []).splice(index, 1);

  }

  downloadAttachment(value: FileGet | null) {

    if (!value) return;

    this.fileService.downloadAttachmentByEndPoint(value);

  }

  updateRow(itemHandling : ItemHandling){

    if(!itemHandling.Oid) return;

    const itemHandlingOid = itemHandling.Oid;

       const data: any = {
      itemOid: itemHandlingOid,
    }

    this.dialog.open(UpdateItemHandlingPriceComponent, {
      data,
      panelClass: 'barcode-tracking-panel',
      minWidth: '400px'
    }).afterClosed().subscribe((value : number) => {

      if(!value) return;

          const routeData = ItemHandlingRouteData()
          routeData
            ?.setId(itemHandlingOid);

          const itemHandlingConfiguration: CtModelConfiguration<any> = CtModelConfiguration
            .create()
            .setRouteData(routeData);

          const param : ItemHandling = itemHandling;
          param.setPrice(value);

          this.modelService
            .putInfos(itemHandlingConfiguration, param)?.subscribe((result : CtWebapiGenericResponse<any>) => {

              this.snackbar.onSuccess();
              itemHandling.Price = value;
              return;

            });

    });

  }

  // openBarcodeTracking(selected: SelectedItemConfiguration | null = null) {
  //
  //   const data: any = {
  //     items: this.itemsWithRequiredTracking
  //   }
  //
  //   if (selected) {
  //
  //     data.selected = selected;
  //
  //   }
  //
  //   this.dialog.open(BarcodeTrackingComponent, {
  //     data,
  //     panelClass: 'barcode-tracking-panel',
  //     minWidth: '400px'
  //   });
  //
  // }

  protected readonly HandlingStateEnum = HandlingStateEnum;
}
