<mat-card>

  <mat-card-content>

    <ct-form *ngIf="formConfiguration" [configuration]="formConfiguration"></ct-form>

    <mat-card-actions
      fxLayout="row wrap"
      fxLayoutAlign="space-between center">

      <div>

        <ct-button [configuration]="cancelButton"></ct-button>

      </div>

      <div>

        <input type="file" accept=".xlsx, .xls, .csv, .xml, .txt" #fileImport
               (change)="import($event)" onclick="this.value=null;" hidden/>

        <button
          *ngIf="configuration?.WorkOrder?.Oid && configuration?.importEnabled"
          (click)="fileImport.click()" mat-raised-button color="primary">
          Import
          <mat-icon>publish</mat-icon>
        </button>

         <ct-button
          *ngIf="creaPackagingButtonConfiguration && isNotClosedState"
          [configuration]="creaPackagingButtonConfiguration">
        </ct-button>

        <app-lead-redirect-button
          *ngIf="leadButtonConfiguration"
          [configuration]="leadButtonConfiguration"></app-lead-redirect-button>

        <!-- TODO: fare uno sviluppo unico con fileAttachments invece che duplicarlo? -->

      </div>

      <div>

        <ct-button
          *ngIf="isQuotation && configuration?.WorkOrder?.Oid"
          [configuration]="acceptQuotationButton"></ct-button>

        <ct-button class="m-l-5" [configuration]="submitButton"></ct-button>

      </div>

    </mat-card-actions>

    <div class="ct-manager-master-detail-container" *ngIf="workOrderConfiguration && workOrderDynamicFormConfiguration.visible">

      <app-work-order-dynamic-form
        [workOrderModelConfiguration]="workOrderConfiguration"
        [datatableContainerFilterValues]="null"></app-work-order-dynamic-form>

    </div>

  </mat-card-content>

</mat-card>
