import {CTBase} from "@ctsolution/ct-base";
import {CTManagerItem} from "./ct-manager-item";
import {Handling} from "./handling";
import {CTManagerUm} from "./ct-manager-um";
import {CTManagerItemStock} from "./ct-manager-item-stock";
import {HandlingStateEnum} from "../enum/handling-state.enum";
import {
  StatusToggleComponentConfiguration
} from "../../components/handling/order-handling-wizard/order-handling-wizard-components/status-toggle-component/status-toggle-component.configuration";
import {FormControl} from "@angular/forms";
import {StatusToggleComponentItemHadlingStates} from "../constants";

const defaultStatusToggleComponentConfiguration = (state?: HandlingStateEnum | null) => StatusToggleComponentConfiguration
  .create<HandlingStateEnum>()
  .setValues(StatusToggleComponentItemHadlingStates())
  .setDirection('col')
  .setControl(new FormControl(state ?? null));

export class ItemHandling extends CTBase<ItemHandling> {

  Oid?: number;
  StartQty: number | null = 0;
  Qty: number | null = 0;
  Item: CTManagerItem | null = null;
  Module: CTManagerItem | null = null;
  Handling: Handling | null = null;
  UM: CTManagerUm | null = null;
  ItemCategoryTrackEnable: boolean | null = null;
  State: HandlingStateEnum | null = null;
  Barcodes: string[] = [];
  UpdateAt: Date | null = null;
  PartialQty: number | null = 0;
  StatusToggleComponentConfiguration: StatusToggleComponentConfiguration<HandlingStateEnum>;
  CTManagerItemStock: CTManagerItemStock | null = null; // mi serve per tenermi in pancia lo stock
  Files: string[] | null = null;
  Price: number | null = null;

  private constructor();
  private constructor(model?: ItemHandling)
  private constructor(model?: ItemHandling) {

    super();

    if (model) this.getClass(model);

    this.StatusToggleComponentConfiguration = defaultStatusToggleComponentConfiguration(model?.State);

    if (model?.State === HandlingStateEnum.Delivered) {

      this.StatusToggleComponentConfiguration
        .control
        ?.disable();

    }

    this.setPartialQty(0);

  }

  public static create = (model?: ItemHandling): ItemHandling => new ItemHandling(model);

  setStartQty(value: number | null): ItemHandling {

    this.StartQty = value;
    return this;

  }

  setPartialQty(value: number | null): ItemHandling {

    this.PartialQty = value;
    return this;

  }

  setQty(value: number | null): ItemHandling {

    this.Qty = value;
    return this;

  }

  setItemFromCTManagerItemStock(value: CTManagerItemStock | null): ItemHandling {

    if (value) {

      this.Item = CTManagerItem.createFromCTManagerItemStock(value);
      this.CTManagerItemStock = value;

    }

    this
      .setUMOid(value?.UMOid ?? -1)
      .setStartQty(0) //impostato a zero per richiesta di xtr
      .setPartialQty(value?.Stock ?? 0);

    if (value?.UMSymbol) {

      this.UM!.Symbol = value.UMSymbol;

    }

    return this;

  }

  setModule(value: CTManagerItemStock | null): ItemHandling {

    if (value) {

      this.Module = CTManagerItem.createFromCTManagerItemStock(value);

    }

    return this;

  }

  setUMOid(Oid: number | null): ItemHandling {

    this.UM = {Oid} as CTManagerUm;
    return this;

  }

  setState(value: HandlingStateEnum) {

    this.State = value;
    return this;

  }

  setPrice(value: number | null): ItemHandling {
    this.Price = value;
    return this;
  }

}
