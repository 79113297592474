import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportItemCheckDialogComponent } from './import-item-check-dialog.component';
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import { CtButtonModule } from '@ctsolution/ct-framework';


@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    CtButtonModule
  ],
  declarations: [ImportItemCheckDialogComponent],
  exports: [ImportItemCheckDialogComponent]
})
export class ImportItemCheckDialogModule { }
