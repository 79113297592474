
<ct-expansion-panel [configuration]="panelConfiguration" *ngIf="panelConfiguration && formConfigurations.length > 0"></ct-expansion-panel>

<ng-template #dynamicFormTemplate>

  <div>

    <ng-container *ngIf="formConfigurations.length > 0">
  
      <ng-container *ngFor="let configuration of formConfigurations">
  
        <ct-dynamic-form-generator #formGenerator [configuration]="configuration"></ct-dynamic-form-generator>
  
      </ng-container>
  
    </ng-container>
  
  </div>

</ng-template>