export class ContactFeeManagerConfiguration {

    Contact: {
      Oid: number | null
    } | null = null;
  
    visible : boolean = false;
  
    private constructor() {
    }
  
    public static create = () => new ContactFeeManagerConfiguration();
  
    setContactOid(value: number | null) {
  
      this.Contact = {
        Oid: value
      }
  
      return this;
  
    }
  
  }
  