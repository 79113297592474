import { Component } from '@angular/core';
import { CtControlConfiguration, CtModelConfiguration, CtModelOnFlyCreateService, CtModelRouteData, CtModelType, MateriaHintExtensionConfig } from '@ctsolution/ct-framework';
import { CTMGeneralService } from '../../../_core/lib/general.service';
import { ItemHandlingRouteData } from '../../../_core/route-data/item-handling.route-data';
import { DEFAULT_DATATABLE_CONFIGURATION } from '../../../_core/constants';
import { ItemHandlingService } from '../item-handling.service';

@Component({
  selector: 'app-item-handling-list',
  templateUrl: './item-handling-list.component.html',
  styleUrls: ['./item-handling-list.component.scss']
})
export class ItemHandlingListComponent {

 configuration: CtModelConfiguration<ItemHandlingListComponent> | null = null;

  constructor(private general: CTMGeneralService, private itemHandlingService : ItemHandlingService, private onFlyCreateHelper : CtModelOnFlyCreateService) {
  }

  ngAfterViewInit() {

    this.setup();

  }

  async setup() {

    this.configuration = CtModelConfiguration
      .create<ItemHandlingListComponent>()
      .setRouteData(ItemHandlingRouteData())
      .setCTDatatableConfiguration(DEFAULT_DATATABLE_CONFIGURATION())
      .setOnCreate(() => this.general.navigateOnCTModelRouter(["item-handling"], "create"))
      .setOnEdit(event => this.openItemHandlingForm(event))
  }

    async openItemHandlingForm(value: any | null = null) {

      const routeData: CtModelRouteData = CtModelRouteData
        .create()
        .setController(ItemHandlingRouteData().controller)
        .setModelType(CtModelType.FORM);

      const configuration: CtModelConfiguration<any> | null = CtModelConfiguration.create()

      if (value.Oid) {

        routeData
          .setId(value.Oid);



      const priceItemHandling = await this.itemHandlingService.getItemSuggestedPrices(value.Oid);

      if(priceItemHandling)
      {

        const hint =  MateriaHintExtensionConfig
          .create()
          .setMessage(`Clicca qui per applicare prezzo consigliato: ${priceItemHandling}`)
          .setAction((control: CtControlConfiguration | null) => this.setPriceForItemHandling(control,priceItemHandling))

          configuration
          .setFieldsCustomTemplates([{
            prop : "Price",
            hint : hint
          }])

      }
    }


      configuration
        .setRouteData(routeData)

      this.onFlyCreateHelper
        .openOnFlyCreate(configuration)
        .afterClosed()
        .subscribe((reload: any) => {

          if (reload) window.location.reload(); // dovrei usare la navigazione sul router, però non sta funzionando correttamente ... per ora facciamo refresh forzato

        });

    }

    setPriceForItemHandling(control : CtControlConfiguration | null, price : number) {

      control?.setValue(price);

    }
}

