import { CTModelDatatableFilter } from "@ctsolution/ct-framework";
import { SchedulerEventPath } from "../scheduler-event-button/scheduler-event-button.component";

export class SchedulerListConfiguration {

  onlyUpdate : boolean = false;
  extraUrlParam : string | null = null;
  navigatePostAction : boolean = true;
  constructor(public Filters : Array<CTModelDatatableFilter> | null = null,public param : SchedulerEventPath | null = null,public Action: ((event: any) => void) | null = null){}

  setAction(value: ((event: any) => void) | null = null): SchedulerListConfiguration {

    this.Action = value;
    return this;

  }

  setOnlyUpdate(value: boolean): SchedulerListConfiguration {

    this.onlyUpdate = value;
    return this;

  }

  setExtraUrlParam(value : string) : SchedulerListConfiguration {

    this.extraUrlParam = value;
    return this;

  }

  setParam(value : SchedulerEventPath | null) : SchedulerListConfiguration {

    this.param = value;
    return this;

  }

  setNavigatePostAction(value: boolean): SchedulerListConfiguration {

    this.navigatePostAction = value;
    return this;

  }
}
