import { AfterViewInit, Component, Input, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { CtBinaryOperator, CtDatatableContainerFilterValues, CTDynamicFormGeneratorComponent, CtDynamicFormGeneratorConfiguration, CTExpansionPanelConfiguration, CtModelConfiguration, CTModelDatatableFilter, CtModelDatatableOperators, CtModelService, CTSingleComponentConfiguration, CTTabConfiguration } from '@ctsolution/ct-framework';
import { CtWebapiGenericResponse } from '@ctsolution/ct-webapi';
import { FormTemplateRouteData } from 'projects/ct-manager/src/app/_core/route-data/form-template.route-data';
import { WorkOrderDataRouteData } from 'projects/ct-manager/src/app/_core/route-data/work-order-data.route-data';

@Component({
  selector: 'app-work-order-dynamic-form',
  templateUrl: './work-order-dynamic-form.component.html',
  styleUrls: ['./work-order-dynamic-form.component.scss']
})
export class WorkOrderDynamicFormComponent implements AfterViewInit {

  formConfigurations: Array<CtDynamicFormGeneratorConfiguration> = [];
  @Input() workOrderModelConfiguration: CtModelConfiguration<any> | null = null;
  @Input() datatableContainerFilterValues: CtDatatableContainerFilterValues | null = null;
  @ViewChildren('formGenerator') formGeneratorComponents: QueryList<CTDynamicFormGeneratorComponent> | null = null;
  
  panelConfiguration : CTTabConfiguration | null = null;
  @ViewChild('dynamicFormTemplate') dynamicFormTemplate!: TemplateRef<any>;

  workOrderDataConfiguration = (): CtModelConfiguration<any> => CtModelConfiguration
    .create()
    .setRouteData(WorkOrderDataRouteData());

  constructor(private modelService: CtModelService<any>) {

  }

  async ngAfterViewInit() {

    this.setup();

    this.panelConfiguration = CTExpansionPanelConfiguration
    .create([
      CTSingleComponentConfiguration.create(this.dynamicFormTemplate).setLabel("workOrderPanelLabel").setExpanded(false)])
      .setButtonsVisible(false);

  }

  getValueFilterOffer(): string | null {

    let value : string | null = null;

    if (this.datatableContainerFilterValues) {

      //value = this.datatableContainerFilterValues.constants.find((item) => item.Field?.includes('CustomerType.Type'))?.Value[0]
      //Do per scontato che venga filtrato solo per un code

      //if(!value) value = this.datatableContainerFilterValues.constants.find((item) => item.Field?.includes('Customer.Type.Type'))?.Value[0]
      //Do per scontato che venga filtrato solo per un code

      //verrà implementato con le offerte
    }

    return value

  }

  getWorkOrderDynamicForms(valueCodeFilter: string | null) {

      const configuration: CtModelConfiguration<any> = CtModelConfiguration
        .create()
        .setRouteData(FormTemplateRouteData());

      const filters = CtModelDatatableOperators
        .create()
        .setFilters([
          CTModelDatatableFilter
            .create()
            .setField('Type')
            .setValue(3)
            .setOperatorType(CtBinaryOperator.Equal)
        ]);

        if(valueCodeFilter){

          const filter = CTModelDatatableFilter.create()
          .setField('Code')
          .setValue(valueCodeFilter)
          .setOperatorType(CtBinaryOperator.Equal);
          filters.Filters.push(filter);

        }

        else{

          filters.Filters.push(

            CTModelDatatableFilter
              .create()
              .setField("[Code] is null OR [Code] = ?")
              .setValue([null]) //poi andrà l'offerta qua
              .setOperatorType(CtBinaryOperator.OrOperator)
          );

        }

      return this.modelService
        .getList(configuration, filters)

    }

      getFormWorkOrderDataValue(formGeneratorOid: number): Promise<WorkOrderDynamicFormDataParameter | null> {

        return new Promise((resolve, reject) => {

          this.getWorkOrderData(formGeneratorOid)
            .subscribe((response: CtWebapiGenericResponse<CtModelConfiguration<Array<WorkOrderDynamicFormDataParameter>>>) => {

                const result = (<WorkOrderDynamicFormDataParameter[]>response.Result.DataSource ?? []);

                if (result.length) {

                  const dataSource: WorkOrderDynamicFormDataParameter = result[0];
                  resolve(dataSource);

                }

                resolve(null);

              },
              (error) => reject(error)
            );
        });

      }


      getWorkOrderData(formGeneratorOid: number) {

        const filters = CtModelDatatableOperators
          .create()
          .setFilters([
            CTModelDatatableFilter
              .create()
              .setField('WorkOrder.Oid')
              .setValue(this.workOrderModelConfiguration?.RouteData?.id)
              .setOperatorType(CtBinaryOperator.Equal),
            CTModelDatatableFilter
              .create()
              .setField('FormTemplate.Oid')
              .setValue(formGeneratorOid)
              .setOperatorType(CtBinaryOperator.Equal)
          ]);

        return this.modelService
          .getList(this.workOrderDataConfiguration(), filters);

      }


        generateWorkOrderDynamicFormDataParameter(params: { value: any, formGeneratorOid: number, workOrderOid: number }) {

          return WorkOrderDynamicFormDataParameter
            .create()
            .setContent(JSON.stringify(params.value))
            .setFormGeneratorOid(params.formGeneratorOid)
            .setWorkOrder(params.workOrderOid);

        }

  insertWorkOrderData(parameter: WorkOrderDynamicFormDataParameter) {

    const workOrderConfiguration = this.workOrderDataConfiguration();

    if (parameter.Oid) {

      workOrderConfiguration
        .RouteData
        ?.setId(parameter.Oid);

    }

    return this.modelService
      .putInfos(workOrderConfiguration, parameter);

  }

  private setChildFormData(idx: number, value: any) {

    const references = this.formGeneratorComponents?.toArray() ?? [];

    const componentRef = references[idx];

    if (componentRef) {

      componentRef
        .form
        .patchValue(value);

    }

  }

   setup(): Promise<void> {

      return new Promise<void>((resolve, reject) => {

        this.getWorkOrderDynamicForms(this.getValueFilterOffer())
          .subscribe((response: CtWebapiGenericResponse<CtModelConfiguration<Array<any>>>) => {

            if (!response.Result.DataSource) {

              reject(new Error('Empty data source'));
              return;

            }

            (<Array<any>>response.Result.DataSource ?? [])
              .forEach(async (cnfg, idx) => {

                const dynamicFormConfiguration = CtDynamicFormGeneratorConfiguration
                  .create()
                  .setFormGeneratorModel(cnfg);

                let existingData = await this.getFormWorkOrderDataValue(cnfg.Oid);

                dynamicFormConfiguration
                  .setOnSubmit((filteredValue: any) => {

                    const parameter: WorkOrderDynamicFormDataParameter = this.generateWorkOrderDynamicFormDataParameter({
                      value: filteredValue.value,
                      formGeneratorOid: dynamicFormConfiguration?.formGeneratorModel?.Oid ?? -1,
                      workOrderOid: this.workOrderModelConfiguration?.RouteData?.id ?? -1
                    });

                    if (existingData?.Oid) {

                      parameter
                        .setOid(existingData.Oid)

                    }

                    this.insertWorkOrderData(parameter)
                      ?.subscribe({
                        next: (response: CtWebapiGenericResponse<number | null>) => {

                          const updatedValue = response.Result ?? existingData?.Oid ?? -1;

                          if (!existingData) existingData = {} as WorkOrderDynamicFormDataParameter
                          (<any>existingData)['Oid'] = updatedValue;

                        },
                        error: (err) => reject(err)
                      });

                  });


                this.formConfigurations
                  .push(dynamicFormConfiguration);

                if (existingData) {

                  const parsedValue = JSON.parse(existingData.Content ?? '')

                  setTimeout(() => this.setChildFormData(idx, parsedValue));

                }

              });

            resolve();

          });

      });

    }

}
export class WorkOrderDynamicFormDataParameter {

  Oid?: number | null;
  FormTemplate: { Oid: number; } | null = null;
  WorkOrder: { Oid: number; } | null = null;
  Content: string | null = null;

  public constructor() {
  }

  public static create = () => new WorkOrderDynamicFormDataParameter();

  setOid(value: number) {

    this.Oid = value;
    return this;

  }

  setContent(value: string) {

    this.Content = value;
    return this;

  }

  setFormGeneratorOid(value: number) {

    this.FormTemplate = {
      Oid: value
    };
    return this;

  }

  setWorkOrder(value: number) {

    this.WorkOrder = {
      Oid: value
    };
    return this;

  }

}
