import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemHandlingListComponent } from './item-handling-list.component';
import { CtModelModule } from '@ctsolution/ct-framework';
import { ItemHandlingService } from '../item-handling.service';

@NgModule({
  imports: [
    CommonModule,
    CtModelModule

  ],
  declarations: [ItemHandlingListComponent],
  exports: [ItemHandlingListComponent],
  providers : [ItemHandlingService]
})
export class ItemHandlingListModule { }
