import {Component} from "@angular/core";
import {CtWebapiGenericResponse} from "@ctsolution/ct-webapi";
import {MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {FileController} from "../../../../../../_core/controllers/file.controller";
import {CTManagerImportConfiguration} from "../../../../../../layouts/ct-manager-import/ct-manager-import.configuration";

@Component({
  selector: "app-item-selection-import",
  template: `
      <div class="item-selection-import-panel" mat-dialog-content>
          <ct-manager-import (close)="dialogRef.close()" [configuration]="configuration"></ct-manager-import>
      </div>`,
  providers: [FileController],
  styles: [
    `.item-selection-import-panel {
      margin: 0px;
      padding: 0px;
    }`
  ]
})
export class ItemSelectionImportComponent {

  configuration: CTManagerImportConfiguration = CTManagerImportConfiguration
    .create()
    .setImportSubscription((file: File) => this.fileController.handlingItemQuantityImport(file))
    .setImportSubscriptionCallBack(<ItemHandling>(response: ItemHandling) => {

      this.dialogRef
        .close(response);

    })
    .setTitle("itemSelectionImport.title")
    .setSubtitle("itemSelectionImport.subTitle")
    .setDescription("")
    .setTemplate({
      path: `assets/templates/CTMItemHandlingImportTemplate.csv`,
      fileName: `CTMItemHandlingImportTemplate${new Date().getFullYear()}`
    })
    .enableCloseAction(true);

  constructor(private fileController: FileController, public dialogRef: MatDialogRef<ItemSelectionImportComponent>) {
  }

}
