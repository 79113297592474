<div class="ct-manager-master-detail-container payments-massive-cntr">
  <h4>{{ "MassivePayments" | translate }}</h4>

  <div class="form-container">
    <ct-form [configuration]="formConfiguration"></ct-form>
    <ct-button [configuration]="searchButton"></ct-button>
  </div>

  <h5>
    La modifica massiva della data di pagamento riguarda unicamente i contratti
    collegati all'Amministratore.
  </h5>

  <ng-container *ngIf="elements">
    <ng-container *ngIf="elements.length; else noEleTemplate">
      <p-table
        [value]="elements"
        [(selection)]="selectedElements"
        dataKey="Oid"
        [paginator]="true"
        [rows]="10"
      >
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 4rem"><p-tableHeaderCheckbox /></th>
            <th>Stato</th>
            <th>Informazioni</th>
            <th>Importo</th>
            <th>Cliente</th>
            <th>Data pagamento</th>
            <th>Utility</th>
            <th>POD/PDR</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-item>
          <tr>
            <td>
              <p-tableCheckbox [value]="item" />
            </td>
            <td>{{ item.Status.Name }}</td>
            <td>{{ item.Description }}</td>
            <td>{{ item.Amount }}</td>
            <td>{{ item.Company?.DisplayName ?? "" }}</td>
            <td>{{ item.PaymentDate | date }}</td>
            <td>{{ item.WorkOrderOfferName }}</td>
            <td>{{ item.WorkOrderPodPdr }}</td>
          </tr>
        </ng-template>
      </p-table>

      <div class="form-container">
        <ct-form [configuration]="submitFormConfiguration"></ct-form>
        <ct-button [configuration]="submitButton"></ct-button>
      </div>

      <h5>
        Seleziona la data di pagamento che verrà applicata ai contratti
        selezionati.
      </h5>
    </ng-container>

    <ng-template #noEleTemplate>
      <h4>Nessun pagamento trovato</h4>
    </ng-template>
  </ng-container>
</div>
