import { Component } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { CtModelConfiguration, CtModelOperator, CtModelRouteData, CtModelType } from "@ctsolution/ct-framework";
import { ContactRouteData } from "../../../_core/route-data/contact.route-data";
import { CompanyContactConfiguration, CompanyContactFilter } from "../../company-contact/company-contact.configuration";
import { ContactFeeManagerConfiguration } from './contact-fee/contact-fee.configuration';
import { CtManagerService } from '../../../_core/lib/ct-manager.service';

@Component({
  selector: 'app-contact-edit',
  template: `
    <ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>

    <ng-container *ngIf="enableMasterDetail">

      <div class="ct-manager-master-detail-container">

        <app-company-contact
          *ngIf="companyContactConfiguration"
          [configuration]="companyContactConfiguration"
          [title]="'contact.references'"></app-company-contact>

      </div>


      <ng-container *ngIf="contactFeeConfiguration.visible">
        <div class="ct-manager-master-detail-container">
          <contact-fee *ngIf="contactFeeConfiguration" [configuration]="contactFeeConfiguration"></contact-fee>
        </div>
      </ng-container>

    </ng-container>`
})
export class ContactEditComponent {

  contactModelRouteData: CtModelRouteData = ContactRouteData()
    .setModelType(CtModelType.MASTERDETAIL);

  configuration: CtModelConfiguration<ContactEditComponent> | null = null;
  companyContactConfiguration: CompanyContactConfiguration | null = null;
  contactFeeConfiguration: ContactFeeManagerConfiguration = ContactFeeManagerConfiguration.create();

  get enableMasterDetail(): boolean {

    return !!this.configuration?.RouteData?.id;

  }

  constructor(private route: ActivatedRoute, private router: Router, private managerService: CtManagerService) {
  }

  ngAfterViewInit() {

    this.setup();

  }

  async setup() {

    const pId: string | null = this.route.snapshot.paramMap.get("id");

    this.managerService.getComponentOverride("contact", "contactFeeManagerConfiguration", this.contactFeeConfiguration).then((component) => {
      return;
    });

    if (pId) {

      this.contactModelRouteData.setId(+pId);

      this.contactFeeConfiguration.setContactOid(+pId)

      this.setupCompanyContactConfiguration();

    }

    this.configuration = CtModelConfiguration
      .create<ContactEditComponent>()
      .setRouteData(this.contactModelRouteData);

  }

  setupCompanyContactConfiguration() {

    this.companyContactConfiguration = CompanyContactConfiguration
      .create()
      .setOperations([CtModelOperator.Update])
      .setCompanyContactFilter(
        CompanyContactFilter
          .create()
          .setField('Contacts.Oid')
          .setValue(this.contactModelRouteData.id))
      .setOnEdit(async contactRelation => {

        const oid = contactRelation.Companies.Oid;

        this.router
          .navigate(['/', 'customer', contactRelation.IsSupplier ? 'supplier' : 'private', 'edit', oid])
          .then()

      })
      .setFieldsCustomTemplates([
        { prop: 'Contacts', visible: false },
        { prop: 'ContactsPriorityInformation', visible: false },
        { prop: 'ContactsType', visible: false },
      ]);

  }

}
