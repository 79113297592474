import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import { CtButtonConfiguration, MAT_RAISED_ACCENT, MAT_RAISED_PRIMARY, MAT_RAISED_WARN } from '@ctsolution/ct-framework';
import { IItemHandlingsImportResult } from '../ct-manager-import.component';

@Component({
  selector: 'app-import-item-check-dialog',
  templateUrl: './import-item-check-dialog.component.html',
  styleUrls: ['./import-item-check-dialog.component.scss']
})
export class ImportItemCheckDialogComponent {

    cancelButton: CtButtonConfiguration = CtButtonConfiguration
      .create()
      .setLabel("Annulla")
      .setAction(() => this.dialogRef.close(false))
      .setMatherialOptions(MAT_RAISED_WARN);

      submitButton: CtButtonConfiguration = CtButtonConfiguration
      .create()
      .setLabel("confirm")
      .setAction(() => this.dialogRef.close(true))
      .setMatherialOptions(MAT_RAISED_PRIMARY);

      copyButton: CtButtonConfiguration = CtButtonConfiguration
      .create()
      .setLabel("Copia elenco")
      .setAction(() => this.onCopy())
      .setMatherialOptions(MAT_RAISED_ACCENT);

  constructor(
      private dialogRef: MatDialogRef<ImportItemCheckDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: IItemHandlingsImportResult) { }

      trackByIndex(index: number): number {
        return index; // Ottimizza *ngFor*
      }

      onCopy(): void {
        const textToCopy = this.data.MissingItemCodes?.join('\n') ?? '';
        navigator.clipboard.writeText(textToCopy).then(() => {
          alert('Elenco copiato negli appunti!');
        });
      }

}
