import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactFeeComponent } from './contact-fee.component';
import { CtModelModule } from '@ctsolution/ct-framework';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    CtModelModule,
    TranslateModule
  ],
  declarations: [ContactFeeComponent],
  exports: [ContactFeeComponent]
})
export class ContactFeeModule { }
